<template>
  <v-card flat class="rounded-10">
    <v-card-title class="text-center py-5">
      <v-row align="center" justify="center">
        <v-card flat width="180" height="150" v-if="mdAndUp">
          <v-img
            :lazy-src="settings.loader"
            height="100"
            contain
            :src="settings.squareLogo"
          ></v-img>
          <v-card-actions class="d-flex">
            <h2
              class="
                fs-20
                align-self-center
                mx-auto
                primary--text
                font-weight-bold
              "
            >
              {{ settings.SiteName }}
            </h2>
          </v-card-actions>
        </v-card>
        <v-icon x-large class="mx-5" color="primary" v-if="mdAndUp">
          mdi-arrow-left-right-bold
        </v-icon>
        <v-card :class="{ 'ml-5': mdAndUp }" flat width="150" height="150">
          <v-img
            :lazy-src="settings.loader"
            contain
            height="100"
            :src="connectApp.logo"
          ></v-img>
          <v-card-actions class="d-flex">
            <h2
              class="
                fs-20
                align-self-center
                mx-auto
                primary--text
                font-weight-bold
              "
            >
              {{ connectApp.name }}
            </h2>
          </v-card-actions>
        </v-card>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row dense class="mb-1">
        <p
          class="
            text-center
            secondary--text
            col-md-8 col-sm-12
            mx-auto
            fs-20
            py-5
          "
        >
          Integration with {{ connectApp.name }} will have access to following
          functionality:
        </p>
        <ul class="col-md-6 col-sm-12 secondary--text mx-auto fs-17">
          <li>
            Accept payments via {{ settings.SiteName }} to your
            {{ connectApp.name }} Connect account
          </li>
          <li>Pay using your {{ connectApp.name }} account.</li>
          <li>Charge credits for email campaigns and SMS/MMS campaigns</li>
          <li>More capabilities coming soon.</li>
        </ul>
      </v-row>
      <v-row dense class="my-2" align="center" justify="center">
        <v-col
          v-if="stripeUser"
          :cols="mdAndUp ? 8 : 12"
          class="mx-auto font-weight-bold text-center primary--text"
        >
          You are currently connected to Stripe
        </v-col>
        <v-col
          v-if="error"
          :cols="mdAndUp ? 8 : 12"
          class="mx-auto font-weight-bold text-center error--text"
        >
          {{ error }}
        </v-col>
      </v-row>
      <v-row dense class="py-3" align="center" justify="center">
        <v-btn
          x-large
          v-if="!stripeUser"
          color="primary"
          class="text-none"
          :disabled="!available"
          :href="stripeConnect"
        >
          Sign In with {{ connectApp.name }}
        </v-btn>
        <v-btn
          x-large
          v-else
          depressed
          color="warning"
          class="text-none"
          :disabled="!available"
          @click.stop="confirmDisconnect = true"
        >
          Disconnect {{ connectApp.name }} ?
        </v-btn>
      </v-row>
    </v-card-text>
    <DeleteDialog
      v-model="confirmDisconnect"
      title="Stripe disconnection confirmation"
      text-content="Are you sure you want to disconnect this account? <br>You won't be able to receive any payment after this."
      @delete="handleStripeDisconnectAccount"
      delete-button-text="Yes, Disconnect account"
    ></DeleteDialog>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import request from '@/services/axios_instance'
import DeleteDialog from '@/common/DeleteDialog.vue'
export default {
  components: { DeleteDialog },
  data: () => ({
    stripeUser: null,
    error: null,
    confirmDisconnect: false
  }),
  computed: {
    ...mapGetters('app', ['connects', 'allowedConnects']),
    connectApp() {
      return this.connects.find((i) => i.id === 'stripe')
    },
    available() {
      return this.allowedConnects.includes(this.connectApp.id)
    },
    stripeConnect() {
      return `https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${this.settings.stripe.client_id}&scope=read_write&redirect_uri=${this.settings.stripe.redirect_uri}`
    }
  },
  mounted() {
    if (this.$route.query.code) {
      this.handleStripeConnectCode(this.$route.query.code)
    } else {
      this.getStripeConnectedAccount()
    }
  },
  methods: {
    getStripeConnectedAccount() {
      request.get(`api/stripe/account`).then(({ data }) => {
        if (data.message && data.error) this.error = data.message
        else this.stripeUser = data
      })
    },
    handleStripeConnectCode(code) {
      request
        .post(`api/stripe/connect`, { code: code })
        .then(({ data }) => {
          this.stripeUser = data
          this.appSnackbar('Successfully connect Stripe account')
        })
        .finally(() => {
          this.$router.replace({ query: {} })
        })
    },
    handleStripeDisconnectAccount() {
      request.post(`api/stripe/disconnect`).then(({ data }) => {
        this.stripeUser = null
        this.appSnackbar(data.message)
        this.confirmDisconnect = false
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>
